import { memo } from 'react';
import { PaginationProps } from '../../model/featured-soundhumans';

const Pagination: React.FC<PaginationProps> = ({ currentPage, setCurrentPage }) => (
  <div className='flex gap-2 mb-10 lg:mb-32'>
    {
      currentPage === 2 && (
        <button
          className={
            `border border-[#D9D9D9] text-16 py-2 px-3 leading-6 font-semibold
              transition duration-500 bg-white text-[#D9D9D7] hover:text-[#4A4A4A]
              hover:border-[#4A4A4A]`
          }
          onClick={() => setCurrentPage(1)}>
          Back
        </button>
      )
    }
    <button
      className={
        `border text-16 py-2 px-3 leading-6 transition duration-500
          hover:text-[#4A4A4A] hover:border-[#4A4A4A] ${currentPage === 1
          ? 'border-[#4A4A4A] text-[#4A4A4A]'
          : 'border-[#D9D9D9] text-[#D9D9D7]'
        }`
      }
      onClick={() => setCurrentPage(1)}
    >
      1
    </button>
    <button
      className={
        `text-16 py-2 px-3 leading-6 hover:bg-white transition duration-500
          hover:text-[#4A4A4A] hover:border-[#4A4A4A] ${currentPage === 2
          ? 'bg-[#4A4A4A] text-white font-semibold border-2 border-[#4A4A4A]'
          : 'border-[#D9D9D9] text-[#D9D9D7] border'
        }`
      }
      onClick={() => setCurrentPage(2)}
    >
      2
    </button>
    {
      currentPage === 1 && (
        <button
          className={
            `border-2 border-[#4A4A4A] text-16 py-2 px-3 leading-6 font-semibold
              transition duration-500 bg-[#4A4A4A] text-white hover:bg-white
              hover:text-[#4A4A4A]`
          }
          onClick={() => setCurrentPage(2)}>
          Next
        </button>
      )
    }
  </div>
);

export default memo(Pagination);
