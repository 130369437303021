import React, { memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import AlignLogo from '../assets/align-array-logo.png';
import HeaderTopBarPromotion from '../components/top-bar-promotion';
import { useOnClickOutside } from '../custom-hooks/useClickOutside';
import FooterIcon from './footer-icon';
import { IAppContextStore, useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';

interface IMenuLink {
  text: string;
  path: string;
  isExternal?: boolean;
}

const headerLinks: IMenuLink[] = [
  {
    text: 'Home',
    path: '/'
  },
  {
    text: 'Products',
    path: '/products'
  },
  {
    text: 'Where to buy',
    path: '/where-to-buy'
  },
  {
    text: 'News',
    path: '/articles'
  },
  {
    text: 'Featured Soundhumans',
    path: '/featured-soundhumans'
  },
  {
    text: 'About us',
    path: '/about-us'
  },
  {
    text: 'Support',
    path: '/support',
  }
];

const MenuLink: React.FC<{ item: IMenuLink, isActive: boolean, onClick: () => void }> = ({ item, isActive, onClick }) => (
  <div className='relative border-b- border-transparent hover:border-jm-black lg:hover:scale-125 transition-all'>
    {
      item.isExternal === true ? (
        <a className='font-bold lg:font-normal cursor-pointer' href={item.path} target='_blank' rel='noreferrer'>{item.text}</a>
      ) : (
        <a
          href={item.path}
          onClick={(e) => {e.preventDefault(); e.stopPropagation(); onClick();}}
          className={`font-bold cursor-pointer ${isActive ? 'lg:font-bold' : 'lg:font-normal'}`}>
          {item.text}
        </a>
      )
    }
  </div>
);

const Header: React.FC = () => {
  const { rawContentFul }: IAppContextStore = useAppContext();
  const heroData = useMemo(() => filterItems(rawContentFul, 'homePageHero')?.[0], [rawContentFul]);
  const footerInfo = useMemo(() => filterItems(rawContentFul, 'footerSection')?.[0], [rawContentFul]);
  const [openNav, setOpenNav] = useState(false);
  const [openLogoMenu, setOpenLogoMenu] = useState(false);
  const [showMobileSearchInput, setShowMobileSearchInput] = useState(false);
  const menuArea: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const location = useLocation();
  const navigate = useNavigate();
  const currentMenuIndex = useMemo(() => {
    if (location.pathname === '/') {
      return 0;
    }

    return _findIndex(headerLinks, (item: IMenuLink) => location.pathname.startsWith(item.path), 1);
  }, [location]);
  //@ts-ignore
  const socialLink: any = useMemo(() => footerInfo?.fields?.socialLink, [footerInfo]);

  useOnClickOutside(menuArea, () => setOpenNav(false));

  useEffect(() => {
    if (openNav === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [openNav]);

  const onClickAMenuLink = useCallback((link: IMenuLink) => navigate(link.path), [navigate]);
  const searchInputMobileRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const searchInputMobile: RefObject<HTMLInputElement> = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  const menuWrapper: RefObject<HTMLInputElement> = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  useOnClickOutside(searchInputMobileRef, () => setShowMobileSearchInput(false));
  useOnClickOutside(menuWrapper, () => setOpenLogoMenu(false));

  useEffect(() => {
    if (showMobileSearchInput === true) {
      searchInputMobile.current?.focus();
    }
  }, [showMobileSearchInput, searchInputMobile]);

  return (
    <>
      <HeaderTopBarPromotion />
      <div ref={searchInputMobileRef} className={`relative flex justify-between pt-26px pb-26px sm:pt-45px sm:pb-23px lg:pt-30px lg:pb-29px border-b border-opacity-40 items-center sm:border-0 lg:border-b px-16px  ${currentMenuIndex === 0 ? 'lg:border-none' : 'border-jm-gray-content'}`}>
        <div ref={menuWrapper} className='relative lg:ml-16'>
          <div className='flex items-center'>
            <div className='cursor-pointer z-10' onClick={() => navigate('/')}>
              <img className='object-cover object-center h-[30px] md:h-11 lg:h-14' src={Logo} alt='SoundTool' />
            </div>
            <div className='w-[20px] ml-2 cursor-pointer' onClick={() => setOpenLogoMenu(!openLogoMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"><path className={`fill-black ${currentMenuIndex === 0 ? 'lg:fill-white': ''}`} d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
            </div>
          </div>
          <div
          className={`transition-all absolute -bottom-[40px] pt-1 border-[#BABACE] ${
            openLogoMenu ? 'pr-[27px] border-t' : 'pr-0 border-none'
            } ${openLogoMenu ? 'translate-y-0 scale-100 md:translate-y-4 lg:translate-y-8' : '-translate-y-10 scale-75'}`}>
              <div className='cursor-pointer' onClick={() => window.location.href = (heroData?.fields?.alignArrayLogoUrl as string || '/')}>
                <img className='object-cover object-center h-[30px] md:h-11 lg:h-14' src={AlignLogo} alt='Align Array' />
              </div>
            </div>
        </div>

        <div className={`hidden lg:flex lg:space-x-7 xl:space-x-11 lg:mr-16 ${currentMenuIndex === 0 ? 'text-white' : ''}`} >
          {
            _map(headerLinks, (item: IMenuLink, index: number) => (
              <MenuLink
                key={index}
                item={item}
                isActive={index === currentMenuIndex} onClick={() => onClickAMenuLink(item)} />
            ))
          }
        </div>
        <div className='lg:hidden cursor-pointer' onClick={() => setOpenNav(!openNav)}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`transition-transform duration-500 ${openNav ? 'rotate-90' : ''}`} width="24.534" height="17.356" viewBox="0 0 24.534 17.356">
            <g transform="translate(-3 -7.5)">
              <path id="Path_100" data-name="Path 100" d="M4.5,18H26.034" transform="translate(0 -1.822)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_101" data-name="Path 101" d="M4.5,9H26.034" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_102" data-name="Path 102" d="M4.5,27H26.034" transform="translate(0 -3.644)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
          </svg>
        </div>
      </div>

      <div className={`transition-all flex justify-end z-10 duration-500 fixed top-0 left-0 bg-jm-slide-background h-screen bg-opacity-60 opacity-100 w-full ${openNav ? '' : 'translate-x-[100%]'}`}>
        <div
          ref={menuArea}
          className='w-9/12 sm:w-4/12 bg-jm-nav-gray opacity-100 h-screen overflow-hidden flex flex-col text-right'>
          <div className='px-5 py-10 bg-jm-white flex flex-col space-y-3'>
          {
            _map(headerLinks, (item: IMenuLink, index: number) => (
              <MenuLink key={index} item={item} isActive={index === currentMenuIndex}  onClick={() => {
                setOpenNav(false);
                onClickAMenuLink(item)
              }}  />
            ))
          }
          </div>
          <div className='flex-auto flex flex-col justify-end items-end p-5 pb-7 text-14'>
            <div className='flex flex-row space-x-2 mb-5'>
              <FooterIcon links={[
                socialLink?.facebook || 'https://www.facebook.com/',
                socialLink?.x || 'https://x.com/ratsoundtools',
                socialLink?.instagram || 'https://www.instagram.com/ratsoundtools/?hl=en',
                socialLink?.youtube || 'https://www.youtube.com/c/soundtoolschannel',
              ]} />
            </div>
            <div>{ footerInfo?.fields?.copyright || `Copyright © ${new Date().getFullYear()} SoundTools. All Rights Reserved.`}</div>
          </div>
        </div>
      </div>
    </>
  )
};

export default memo(Header);
