import { memo } from 'react';
import { Content } from '../../../model/featured-soundhumans';

const Text: React.FC<{ content: Content }> = ({ content }) => {
  if (content?.nodeType === 'hyperlink') {
    return (
      <a
        href={content?.data?.uri}
        target='_blank'
        rel='noopener noreferrer'
        className='italic transition-colors duration-500 hover:text-[#DE4F2D]'
      >
        {content?.content?.map((item: any) => item?.value)}
      </a>
    );
  }

  return (
    <>
      {content?.content?.map((item: any) => item?.value)}
    </>
  );
}

export default memo(Text);
