import { memo } from 'react';
import ReadMoreButton from './ReadMoreButton';
import { getDate } from '../../helpers/date-time';
import { LatestArticlesProps } from '../../model/featured-soundhumans';

const LatestStoryCard: React.FC<LatestArticlesProps> = ({ article }) => (
  <div className='relative block'>
    {
      article?.feature_image && (
        <img
          src={article?.feature_image}
          alt={article?.title}
          className='h-[200px] lg:h-[150px] w-full object-cover'
        />
      )
    }
    <h4 className='my-2 font-inter capitalize font-semibold
      text-18 leading-6 text-[#4A4A4A]'>
      {article?.title}
    </h4>
    {
      article?.published_at && (
        <div className='text-[#666666] text-16 leading-6'>
          {getDate(new Date(article?.published_at))}
        </div>
      )
    }
    <p className='text-[#666666] leading-6 line-clamp-3 my-4'>
      {article.html}
    </p>
    <ReadMoreButton
      link={`/articles/${article?.slug}`}
    />
  </div>
);

export default memo(LatestStoryCard);
