import { memo } from 'react';
import _get from 'lodash/get';
import { getImageObject } from '../../helpers/featured-soundhumans';
import { TopCardProps } from '../../model/featured-soundhumans';
import { formatDate } from '../../helpers/date-time';
import ReadMoreButton from './ReadMoreButton';

const CompactCard: React.FC<TopCardProps> = ({ soundHuman }) => (
  <div key={_get(soundHuman, 'sys.id', '')}
    className="flex flex-col md:flex-row border-b border-[#d0d0d0] my-5 mx-4 md:gap-6">
    {
      getImageObject(soundHuman, 'thumbnail')?.url && (
        <img
          src={getImageObject(soundHuman, 'thumbnail')?.url}
          alt={getImageObject(soundHuman, 'thumbnail')?.title}
          className="w-full md:max-h-[150px] object-cover flex-1"
          loading='lazy'
        />
      )
    }
    <div className="flex-2">
      <div className="mb-4 mt-4 md:mt-0">
        <h2 className="font-semibold text-20 text-[#4A4A4A]">
          {_get(soundHuman, 'fields.fullName')}
        </h2>
        <p className='text-[#6C757D] text-16 leading-6'>
          {formatDate(new Date(_get(soundHuman, 'fields.publishedDate', '')))}
        </p>
      </div>
      <p className="text-18 leading-6 text-[#666666] my-3">
        {soundHuman.fields.shortDescription}
      </p>
      <div className="mb-5">
        <ReadMoreButton
          link={`/featured-soundhumans/${_get(soundHuman, 'fields.slug', '')}`}
        />
      </div>
    </div>
  </div>
);

export default memo(CompactCard);
