import { memo, useMemo } from 'react'
import Text from './Text';
import { Content } from '../../../model/featured-soundhumans';

const Heading: React.FC<{ content: Content }> = ({ content }) => {
  const headingValue = useMemo(() => (
    content?.content?.[0]?.value ?? ''
  ), [content]);
  const restContent = useMemo(() => (
    content?.content ?? []
  ), [content]);
  const marks = useMemo(() => (
    content?.content?.[0]?.marks ?? []
  ), [content]);

  if (content?.nodeType === 'heading-1') {
    return (
      <h1 className='text-center font-semibold text-32 text-[#4A4A4A] my-4'>
        {headingValue}
        {
          restContent?.length > 1 && restContent?.map((item: any, index: number) => (
              <Text key={`heading1-text-${index}`} content={item} />
            ))
        }
      </h1>
    )
  }

  if (content?.nodeType === 'heading-2') {
    return (
      <h2 className={
        `font-semibold text-[#4A4A4A] my-3 leading-8 md:leading-10 ${
          marks?.[0]?.type === 'code' ? 'text-center text-30' : 'text-28'
        }`
      }>
        {headingValue}
        {
          restContent?.length > 1 && restContent?.map((item: any, index: number) => (
              <Text key={`heading2-text-${index}`} content={item} />
            ))
        }
      </h2>
    )
  }

  return null;
}

export default memo(Heading);
