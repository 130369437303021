const JotFormSubmit: React.FC = () => (
  <div className='flex flex-col justify-center items-center'>
    <h2 className='font-semibold text-[#4A4A4A] text-32 leading-9 md:leading-12 my-4'>
      Want to be a featured Sound Human?
    </h2>
    <a
      className='text-16 text-[#4A4A4A] font-semibold
                py-2 px-5 border border-[#4A4A4A] mb-6
                transition-all duration-300 ease-in-out
                hover:bg-[#4A4A4A] hover:text-white'
      href='https://www.jotform.com/form/203087044354047'
      target='_blank'
      rel="noreferrer">
      Submit Here
    </a>
  </div>
)

export default JotFormSubmit;
