import { memo, useCallback, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import _orderBy from 'lodash/orderBy';
import _first from 'lodash/first';
import _get from 'lodash/get';
import { Entry } from 'contentful';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import { FeaturedSoundHuman } from '../model/featured-soundhumans';
import TopCard from '../components/featured-soundhumans/TopCard';
import CompactCard from '../components/featured-soundhumans/CompactCard';
import SocialIconsGroup from '../components/featured-soundhumans/SocialIconsGroup';
import Pagination from '../components/featured-soundhumans/Pagination';
import Subscriber from '../components/subscriber';
import { formatDate } from '../helpers/date-time';

const SoundHumansPage: React.FC = () => {
  const { rawContentFul, allPosts } = useAppContext();
  const [searchParams, setSeachParams] = useSearchParams();
  const pageFromParams: string | null = searchParams.get('page');

  const currentPage: number = useMemo(() => pageFromParams === null ? 1 : parseInt(pageFromParams), [pageFromParams])
  const socialInfo = useMemo(() => filterItems(rawContentFul, 'footerSection')?.[0], [rawContentFul]);
  const socialLink: any = useMemo(() => socialInfo?.fields?.socialLink, [socialInfo]);
  const featuredSoundhumans: Entry<FeaturedSoundHuman>[] = useMemo(() => {
    const list = _orderBy(filterItems(rawContentFul, 'featuredSoundhumans'), 'fields.publishedDate', 'desc');

    return (
      currentPage === 1 ? list.slice(0, 5) : list.slice(5, list.length)
    ) as Entry<FeaturedSoundHuman>[];
  }, [rawContentFul, currentPage]);
  const latestArticles = useMemo(() => _orderBy(allPosts, 'published_at', 'desc').slice(0, 3), [allPosts]);
  const firstSoundHuman: Entry<FeaturedSoundHuman> = useMemo(() => (
    _first(featuredSoundhumans) as Entry<FeaturedSoundHuman>
  ), [featuredSoundhumans]);

  const setCurrentPage = useCallback((newPage: number) => {
    setSeachParams({ page: newPage.toString() });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [setSeachParams]);

  return (
    <div className='px-4 lg:mx-16'>
      <div className='max-w-6xl w-full mx-auto'>
        <h1 className='font-bold text-30 text-[#4A4A4A] uppercase my-4'>
          Featured SoundHumans
        </h1>
        <div className='grid grid-cols-1 lg:grid-cols-[70%_30%] gap-4'>
          <div className='relative'>
            {
              currentPage === 1 && (
                <TopCard soundHuman={firstSoundHuman} />
              )
            }
            <div className='-mx-4'>
              {
                featuredSoundhumans.slice(
                  currentPage === 1 ? 1 : 0, featuredSoundhumans.length
                ).map((soundHuman) => (
                  <CompactCard key={_get(soundHuman, 'sys.id', '')} soundHuman={soundHuman} />
                ))
              }
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <div className='relative mb-20'>
            <div className='h-auto flex flex-col relative bg-[#F6F6F6] p-7 gap-6'>
              <div>
                <Link to='/articles' className='font-semibold py-1 text-[#222222] leading-6'>
                  Back to SoundTools News
                </Link>
                <h3 className='text-[#4A4A4A] text-20 font-semibold leading-6 my-4'>
                  Subscribe to our mailing list!
                </h3>
                <Subscriber formFrom='featured-soundhumans' />
              </div>
              <div>
                <h3 className='text-[#4A4A4A] font-semibold leading-6 text-20 my-4'>Follow us on social media</h3>
                <SocialIconsGroup links={[
                  socialLink?.facebook || 'https://www.facebook.com/',
                  socialLink?.instagram || 'https://www.instagram.com/ratsoundtools/?hl=en',
                  socialLink?.x || 'https://x.com/ratsoundtools',
                  socialLink?.youtube || 'https://www.youtube.com/c/soundtoolschannel'
                ]} />
              </div>
              <div>
                <h3 className='text-[#4A4A4A] font-semibold leading-6 text-24 my-4 uppercase text-center'>
                  Latest Posts
                </h3>
                <div className='grid grid-cols-1 gap-6'>
                  {
                    latestArticles.map((article) => article?.feature_image && (
                      <Link to={`/articles/${article?.slug}`} key={article?.id}>
                        <img src={article?.feature_image} alt={article?.title} />
                        <h4 className='my-2 font-inter capitalize'>{article?.title}</h4>
                        <p className='text-[16px] text-[#7B7B7B]'>
                          {formatDate(new Date(article?.published_at || ''))}
                        </p>
                      </Link>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SoundHumansPage);
