import { memo } from 'react';
import { Link } from 'react-router-dom';
import { ReadMoreButtonProps } from '../../model/featured-soundhumans';

const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({ link }) => (
  <Link to={link}
    className='text-[#222222]
      font-semibold py-2 transition-all duration-500 text-16
      px-0
      hover:border hover:border-[#4A4A4A] hover:text-[#999999]
      hover:px-4'
  >
    More
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width={16} height={16} fill='currentColor' className='inline-block ml-1'>
      <path d='M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z' />
    </svg>
  </Link>
);

export default memo(ReadMoreButton);
