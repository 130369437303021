import { memo } from 'react';
import Heading from './Heading';
import Paragraph from './Paragraph';
import EmbeddedAssetBlock from './EmbeddedAssetBlock';
import { Content } from '../../../model/featured-soundhumans';

const Element: React.FC<{content: Content}> = ({ content }) => {
  if (['heading-1', 'heading-2'].includes(content?.nodeType)) {
    return <Heading content={content} />;
  }

  if (content?.nodeType === 'hr') {
    return <hr />;
  }

  if (content?.nodeType === 'paragraph') {
    return <Paragraph content={content} />;
  }

  if (content?.nodeType === 'embedded-asset-block') {
    return <EmbeddedAssetBlock content={content} />;
  }

  return null;
}

export default memo(Element);
