import React, { useState } from 'react';

// @ts-ignore
const LightCarousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstItem = currentIndex === 0;
    const newIndex = isFirstItem ? children.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastItem = currentIndex === children.length - 1;
    const newIndex = isLastItem ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative w-[100vw] md:w-auto">
      <div className="overflow-hidden">
        <div className="whitespace-nowrap transition-transform duration-500"
             style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {React.Children.map(children, (child, index) => (
            <div className="inline-block w-full" key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <div onClick={goToPrevious} className="absolute top-1/2 cursor-pointer left-0 transform -translate-y-1/2 text-white p-2">
        <svg xmlns="http://www.w3.org/2000/svg" className='rotate-180' viewBox="0 0 320 512" height={15}><path fill='#ffffff' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
      </div>
      <div onClick={goToNext} className="absolute top-1/2 cursor-pointer right-0 transform -translate-y-1/2 text-white p-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='#ffffff' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
      </div>
    </div>
  );
};

export default LightCarousel;
