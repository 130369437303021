import { memo, useMemo } from 'react'
import Text from './Text';
import { Content } from '../../../model/featured-soundhumans';

const Paragraph: React.FC<{ content: Content }> = ({ content }) => {
  const elementClass = useMemo(() => {
    const arrClass = ['text-16', 'leading-6', 'text-[#666666]', 'my-3'];

    if (content?.content?.[0]?.marks?.[0]?.type === 'italic') {
      arrClass.push('text-center');
    }

    return arrClass.join(' ');
  }, [content]);
  const paragraph = useMemo(() => (
    content?.content?.[0]?.value ?? ''
  ), [content]);
  const restContent = useMemo(() => (
    content?.content ?? []
  ), [content]);

  if (paragraph.includes('iframe')) {
    return (
      <div
        className='relative w-full flex justify-center mb-6'
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    );
  }

  return (
    <p className={elementClass}>
      {paragraph}
      {
        restContent?.length > 1 && restContent?.map((item: any, index: number) => (
          <Text key={`paragraph-text-${index}`} content={item} />
        ))
      }
    </p>
  ) 
}

export default memo(Paragraph);
