import { memo, useMemo } from 'react';
import _get from 'lodash/get';
import { Content } from '../../../model/featured-soundhumans';

const EmbeddedAssetBlock: React.FC<{ content: Content }> = ({ content }) => {
  const imgClass = useMemo(() => {
    return content?.data?.target?.fields?.description ?? 'w-full'
  }, [content?.data?.target?.fields?.description]);

  return (
    <img
      src={_get(content, 'data.target.fields.file.url', '')}
      alt={_get(content, 'data.target.fields.title', '')}
      className={`object-cover ${imgClass}`}
    />
  )
}

export default memo(EmbeddedAssetBlock);
