import _get from 'lodash/get';
import { Entry } from 'contentful';
import { FeaturedSoundHuman } from '../model/featured-soundhumans';

export const getImageObject = (
  soundHuman: Entry<FeaturedSoundHuman>,
  imageFieldName: string
): {
  url: string;
  title: string;
} => ({
  url: _get(soundHuman, `fields.${imageFieldName}.fields.file.url`, ''),
  title: _get(soundHuman, `fields.${imageFieldName}.fields.title`, ''),
});
